'use client'

import { useEffect } from 'react'
import { useUser } from '@auth0/nextjs-auth0/client'
import { GoogleTagManager, sendGTMEvent } from '@eo/next-ui'

export default function GoogleTagManagerWrapper() {
  const gtmId = process.env.NEXT_PUBLIC_GTM_ID
  const gtmSource = process.env.NEXT_PUBLIC_GTM_SOURCE

  const { user, isLoading } = useUser()

  useEffect(() => {
    if (!isLoading && user) {
      const loginMethod = user['https://mijn.eo.nl/loginMethod'] as string
      const loginsCount = user['https://mijn.eo.nl/loginsCount'] as number

      sendGTMEvent({
        event: 'eo_login',
        method: loginMethod,
      })

      if (loginsCount < 1) {
        sendGTMEvent({
          event: 'eo_sign_up',
          method: loginMethod,
        })
      }
    }
    // Only trigger when loading once when loading is done
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return gtmId ? (
    <GoogleTagManager
      gtmId={gtmId}
      source={gtmSource}
    />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  )
}
