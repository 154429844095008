import type { Subject } from '@eo/graphql-types'

import { constants } from '@/constants'

export const orderSubjects = (subjects: Subject[]) =>
  subjects.slice().sort((a, b) => {
    const indexA = constants.subjectOrder.indexOf(a.subject!)
    const indexB = constants.subjectOrder.indexOf(b.subject!)

    // if A is not defined in the config, move B to the back (1),
    //  unless B is not defined as well, then mark A and B as equal (0)
    if (indexA === -1) return indexB === -1 ? 0 : 1

    // if B is not defined in the config, move A to the back (-1)
    if (indexB === -1) return -1

    // otherwise, just compare the indexes
    return indexA - indexB
  })
