'use client'

// ^ this file needs the "use client" pragma
import { from, HttpLink } from '@apollo/client'
import {
  ApolloClient,
  ApolloNextAppProvider,
  InMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support'

import { errorLink } from './errorLink'
import { sentryLink } from './sentryLink'

function makeClient() {
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_HOST,
    fetchOptions: { cache: 'no-store' },
  })

  return new ApolloClient({
    cache: new InMemoryCache(),
    link:
      typeof window === 'undefined'
        ? from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            sentryLink,
            errorLink,
            httpLink,
          ])
        : from([sentryLink, errorLink, httpLink]),
  })
}
export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return <ApolloNextAppProvider makeClient={makeClient}>{children}</ApolloNextAppProvider>
}
