'use client'

import type { ComponentProps } from 'react'

import { usePathname } from 'next/navigation'
import { HeaderTab, iconBlocks, iconMonitorPlay, iconNews } from '@eo/next-ui'

export default function HeaderTabsList() {
  const pathname = usePathname()

  const tabs: ComponentProps<typeof HeaderTab>[] = [
    {
      ariaLabel: 'Lezen',
      href: '/',
      label: 'Lezen',
      icon: iconNews,
    },
    {
      ariaLabel: 'Kijk & luister',
      label: 'Kijk & luister',
      href: '/kijk-en-luister',
      icon: iconMonitorPlay,
    },
    {
      ariaLabel: 'Doen',
      label: 'Doen',
      href: '/doen',
      icon: iconBlocks,
    },
  ]

  const isActive = (href: string) => {
    if (href === pathname) {
      return true
    }
    if (href === '/kijk-en-luister' && pathname.startsWith('/kijk-en-luister')) {
      return true
    }
    if (href === '/doen' && pathname.startsWith('/doen')) {
      return true
    }
    return false
  }

  return tabs.map((props) => (
    <HeaderTab
      key={props.href}
      active={isActive(props.href)}
      textColor={isActive(props.href) ? 'purple-700' : 'gray-500'}
      color={isActive(props.href) ? 'indigo-50' : 'gray-500'}
      className="lg:mr-5"
      icon={props.icon}
      {...props}
    />
  ))
}
