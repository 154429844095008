import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/nextjs'

// Log any GraphQL errorsO or network error that occurred
export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((err) => {
      console.log(
        `[GraphQL error]: Message: ${err.message}, Location: ${JSON.stringify(err.locations)}, Path: ${JSON.stringify(err.path)}`,
      )
      Sentry.captureException(err)
    })
  if (networkError) {
    console.log(`[Network error]: ${networkError.message}`)
    Sentry.captureException(networkError)
  }
})
