'use client'

import type { Subject } from '@eo/graphql-types'

import { useQuery } from '@apollo/client'
import { HeaderLink, HeaderLinkGroup, Socials } from '@eo/next-ui'

import { constants } from '@/constants'
import GET_SUBJECTS from '@/queries/getSubjects.graphql'
import { orderSubjects } from '@/utils/orderSubjects'
import SearchFormRedirect from './SearchFormRedirect'

export default function HeaderMenuContent() {
  const { data: subjectData } = useQuery<{ subjects: Subject[] }>(GET_SUBJECTS, {
    errorPolicy: 'all',
  })

  const subjects: Subject[] = subjectData?.subjects ?? []

  return (
    <>
      <SearchFormRedirect className="col-span-full lg:col-span-4 lg:col-start-2" />
      <div className="col-span-full sm:col-span-6 lg:col-span-3 lg:col-start-2">
        <HeaderLinkGroup>
          <HeaderLink
            ariaLabel="Lezen"
            label="Lezen"
            href="/"
            className="text-black"
          />
          <HeaderLink
            ariaLabel="Kijk & luister"
            label="Kijk & luister"
            href="/kijk-en-luister"
            className="text-black"
          />
          <HeaderLink
            ariaLabel="Doen"
            label="Doen"
            href="/doen"
            className="text-black"
          />
          <HeaderLink
            ariaLabel="Nieuwsbrief"
            label="Nieuwsbrief"
            href="/nieuwsbrief"
            className="text-black"
          />
          <HeaderLink
            ariaLabel="Whatsapp-panel"
            label="Whatsapp-panel"
            href="/whatsapp"
            className="text-black"
          />
          <HeaderLink
            ariaLabel="Over DIT"
            label="Over DIT"
            href="/over-dit"
            className="text-black"
          />
        </HeaderLinkGroup>
      </div>
      <HeaderLinkGroup
        className="sm:col-span-6 lg:col-span-3"
        label="Onderwerpen"
        labelClassName="text-purple-700"
      >
        {orderSubjects(subjects).map((subject) => (
          <HeaderLink
            key={subject.subject || subject.id}
            ariaLabel={subject.subject!}
            className="text-black"
            href={`/onderwerp/${subject.slug}`}
            label={subject.subject!}
          />
        ))}
      </HeaderLinkGroup>
      <HeaderLinkGroup
        className="lg:col-span-4"
        label="Meer van DIT"
        labelClassName="text-purple-700"
      >
        <Socials
          className="flex-wrap"
          size={48}
          items={constants.socials}
        />
      </HeaderLinkGroup>
    </>
  )
}
