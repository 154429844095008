export default function imageLoader({
  src,
  width,
  quality,
}: {
  src: string
  width: number
  quality?: number
}) {
  if (!width) return src

  // Image is local or relative
  if (!src.startsWith('http')) return src

  // Image is hosted by Prepr CDNs, so use its resizer
  if (src.includes('cdn.eo.nl') || src.includes('b-cdn.net')) {
    return width ? src.replace('{format}', `w_${width}${quality ? `,q_${quality}` : ''}`) : src
  }

  // Image is hosted by POMS CDNs, so use its resizer
  if (src.includes('images.poms.omroep.nl/image')) {
    const maxPOMSWidth = 3000 // POMS CDN has a limited width on images
    const currentWidth = width >= maxPOMSWidth ? maxPOMSWidth : width
    return currentWidth ? src.replace('/image/', `/image/s${currentWidth}/`) : src
  }

  // Image is hosted elsewhere, so use our Cloudflare resizer
  return `https://www.eo.nl/cdn-image?s=${encodeURIComponent(src)}&w=${width}`
}
