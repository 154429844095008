'use client'

import type { Subject } from '@eo/graphql-types'
import type { NewsletterSubscription } from '@eo/next-ui'

import Link from 'next/link'
import { useQuery } from '@apollo/client'
import { useUser } from '@auth0/nextjs-auth0/client'
import {
  Button,
  Footer as FooterComponent,
  FooterContent,
  FooterList,
  PreprNewsletter,
  sendGTMEvent,
  Socials,
} from '@eo/next-ui'

import { constants } from '@/constants'
import { createNewsletterSubscription } from '@/interactions/actions/createNewsletterSubscription'
import GET_SUBJECTS from '@/queries/getSubjects.graphql'
import { orderSubjects } from '@/utils/orderSubjects'

export default function Footer() {
  const navigationLinks = {
    title: 'Ga naar',
    items: [
      {
        label: 'Homepage',
        path: '/',
      },
      {
        label: 'Kijk & luister',
        path: '/kijk-en-luister',
      },
      {
        label: 'Doen',
        path: '/doen',
      },
      {
        label: 'Nieuwsbrief',
        path: '/nieuwsbrief',
      },
      {
        label: 'Whatsapp-panel',
        path: '/whatsapp',
      },
      {
        label: 'Over DIT',
        path: '/over-dit',
      },
    ],
  }

  const { data: subjectData } = useQuery<{ subjects: Subject[] }>(GET_SUBJECTS, {
    errorPolicy: 'all',
  })

  const subjectLinks = {
    title: 'Onderwerpen',
    items: orderSubjects(subjectData?.subjects ?? []).map((subject) => ({
      label: subject.subject!,
      path: `/onderwerp/${subject.slug}`,
    })),
  }

  const { user } = useUser()

  const onSubscribe = async (subscribeData: NewsletterSubscription) => {
    try {
      const { success } = await createNewsletterSubscription(subscribeData)
      sendGTMEvent({
        event: 'eo_subscribe',
        channel: 'newsletter',
        item_id: subscribeData.campaignId,
        promotional_text: 'Schrijf je in en ontvang elke zaterdag het beste van DIT in je mailbox.',
        subject: 'DIT',
        logged_in: !!user,
      })
      return {
        success,
      }
    } catch (err) {
      return {
        success: false,
        error: err,
      }
    }
  }

  return (
    <FooterComponent
      colors={{
        mainColor: 'black',
        mainBackgroundColor: 'indigo-50',
        bottomTextColor: 'white',
        bottomBackgroundColor: 'purple-700',
      }}
      style={{
        '--gradient': 'linear-gradient(265.8deg, #6A27FF -11.65%, #81009A 89.73%)',
      }}
    >
      <FooterContent className="mx-auto grid grid-cols-12 gap-x-6 gap-y-12">
        <div className="col-span-12 sm:col-span-6 lg:col-span-4">
          <FooterList label={navigationLinks.title}>
            {navigationLinks.items.map((item) => (
              <li
                className="mr-5"
                key={item.label}
              >
                <Link href={item.path}>{item.label}</Link>
              </li>
            ))}
          </FooterList>
          <div className="mt-12 flex flex-col gap-4">
            <h2 className="font-body text-base font-normal">Volg ons op</h2>
            <Socials
              className="flex-wrap"
              size={48}
              items={constants.socials}
            />
          </div>
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4">
          <FooterList label={subjectLinks.title}>
            {subjectLinks.items.map((item) => (
              <li
                className="mr-5"
                key={item.label}
              >
                <Link href={item.path}>{item.label}</Link>
              </li>
            ))}
          </FooterList>
        </div>
        <div className="col-span-12 flex flex-col gap-12 sm:flex-row lg:col-span-4 lg:flex-col">
          <div className="flex grow flex-col gap-4">
            <h2 className="mt-1 font-display text-2xl/none font-bold">
              Jouw verhaal delen of heb je een tip? Stuur ons dan een Whatsapp!
            </h2>
            <Button
              className="w-full bg-[image:--gradient] text-white hover:bg-[length:200%,100%] focus-visible:bg-[length:200%,100%] sm:w-fit"
              label="App direct de redactie"
              rounded
              size="lg"
              to="https://api.whatsapp.com/send/?phone=31645923535"
            >
              App direct de redactie
            </Button>
          </div>
          <PreprNewsletter
            className="@2xl:gap-5"
            item={{
              campaign: '7017U0000009AXiQAM',
              head: 'Schrijf je in en ontvang elke zaterdag het beste van DIT in je mailbox.',
            }}
            onSubscribe={onSubscribe}
          />
        </div>
      </FooterContent>
    </FooterComponent>
  )
}
