'use client'

import Link from 'next/link'
import {
  Button,
  HeaderAccountMenu,
  Header as HeaderBar,
  HeaderBrands,
  HeaderMenu,
  HeaderMenuTopbar,
  HeaderNpoIcon,
  HeaderRight,
  HeaderTabs,
  Icon,
  iconSearch,
  Logo,
} from '@eo/next-ui'

import HeaderMenuContent from './HeaderMenuContent'
import HeaderTabsList from './HeaderTabsList'

export default function Header() {
  /**
   * Using this hook forces the component to be a client component, but the Header from
   * @eo/next-ui is already a client component, so the effect on the site should be minimal.
   */
  return (
    <HeaderBar
      colorFrom="white"
      colorTo="white"
      layoutSwitch="md"
      collapsing
    >
      <HeaderMenu
        colorFrom="blue-50"
        colorTo="blue-50"
        buttonProps={{ color: 'blue-50', textColor: 'purple-700' }}
      >
        <HeaderMenuTopbar>
          <HeaderAccountMenu
            brand="dit"
            brandSize={40}
            buttonProps={{ textColor: 'purple-700', className: 'max-sm:order-1' }}
          />
          <Link
            href="https://www.eo.nl/"
            className="max-sm:hidden"
            aria-label="Ga naar de EO."
          >
            <Logo size={24} />
          </Link>
          <HeaderNpoIcon
            variant="npo"
            className="max-sm:sr-only"
          />
        </HeaderMenuTopbar>
        <HeaderMenuContent />
      </HeaderMenu>
      <HeaderBrands className="-my-2 max-sm:scale-75">
        <Link
          href="/"
          aria-label="Ga naar de homepage."
        >
          <Logo
            logo="dit"
            size={48}
          />
        </Link>
        <div className="-order-1 h-10 w-0.5 rounded-full bg-gray-100 sm:hidden" />
        <Link
          href="https://www.eo.nl/"
          className="-order-2 sm:hidden"
          aria-label="Ga naar de EO."
        >
          <Logo size={28} />
        </Link>
      </HeaderBrands>
      <HeaderTabs layoutSwitch="md">
        <HeaderTabsList />
      </HeaderTabs>
      <HeaderRight>
        <Button
          className="max-lg:hidden"
          outlined
          bgOpacity={0}
          label="Zoek een artikel"
          size="md"
          rounded
          textColor="purple-700"
          to="/zoeken"
        >
          <Icon
            icon={iconSearch}
            size={20}
          />
        </Button>
        <HeaderAccountMenu
          brand="dit"
          brandSize={40}
          buttonProps={{ textColor: 'purple-700', className: 'max-sm:order-1' }}
        />
        <Link
          href="https://www.eo.nl/"
          className="max-sm:hidden"
          aria-label="Ga naar de EO."
        >
          <Logo size={24} />
        </Link>
        <HeaderNpoIcon
          variant="npo"
          className="max-sm:sr-only"
        />
      </HeaderRight>
    </HeaderBar>
  )
}
